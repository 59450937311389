<template>
  <div class="wrapper">
    <v-row class="pb-0 px-0 mx-0" style="height: 100%; width: 100%">
      <v-col class="flex px-0">
        <div class="mt-1 pl-3">
          <img style="width: 200px; height: 50px" :src="prescriptionQr" />
        </div>
        <div class="pl-2 text-sm" style="line-height: 0.8rem; width: 280%">
          <div class="flex items-center">
            <img src="../assets/logo/logo.png" alt="logo" class="w-9 h-7 mb-0" />
            <p class="brand mt-4">BẢO ĐẠI ĐƯỜNG</p>
          </div>
          <div class="mt-1" style="line-height: 1.2rem; font-size: 13px">
            <p class="mb-0">
              Họ tên:
              <span
                ><b>{{ prescriptionName }}</b></span
              >
            </p>
          </div>
          <div class="mt-0" style="line-height: 1.2rem; font-size: 13px">
            <p class="mb-0">
              Ngày sắc:
              <span
                ><b>{{ currentDate }}</b></span
              >
            </p>
            <p class="mb-0">
              Hạn dùng:
              <span
                ><b>{{ experiedDate }}</b></span
              >
            </p>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { SESSION_STORAGE_KEY } from "~/utils/constants.js";
export default {
  name: "MauInTem",
  data() {
    return {
      currentDate: null,
      experiedDate: null,
      prescriptionQr: null,
      prescriptionName: null,
    };
  },
  created() {
    // Get data from session
    this.prescriptionQr = sessionStorage.getItem(SESSION_STORAGE_KEY.PRESCRIPTION_QR);
    this.prescriptionName = sessionStorage
      .getItem(SESSION_STORAGE_KEY.PRESCRIPTION_NAME)
      .toUpperCase();
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    const formattedToday = dd + "/" + mm + "/" + yyyy;

    this.currentDate = formattedToday;

    // Experied
    var newDate = new Date(today.setMonth(today.getMonth() + 2));
    const yyyy2 = newDate.getFullYear();
    let mm2 = newDate.getMonth() + 1; // Months start at 0!
    let dd2 = newDate.getDate();

    if (dd2 < 10) dd = "0" + dd2;
    if (mm2 < 10) mm2 = "0" + mm2;

    const formattedToday2Month = dd2 + "/" + mm2 + "/" + yyyy2;

    this.experiedDate = formattedToday2Month;
  },
  computed: {},

  methods: {
    print() {
      window.print();
    },
  },
};
</script>

<style style type="scss" media="print" scoped>
/* @page { */
/* size: 58mm 40mm; */
/* margin: 0; */
/* orientation: portrait; */
/* } */

@media print {
  @page {
    size: 102mm 60mm;
    margin: 0;
    /* orientation: portrait; */
  }

  .button-print {
    display: none;
  }

  /* html, body {
    width: 58mm;
    height: 40mm;
  } */
}

.brand {
  color: black !important;
  font-family: "Mabella" !important;
  font-size: 22px !important;
}

/* .qr-code {
  width: 48px !important;
  height: 80px !important;
} */

.print-show {
  display: none;
}

.print-hidden {
  display: block;
}

.wrapper {
  transform: rotate(90deg);
  transform-origin: bottom left;

  position: absolute;
  top: -100vw;

  height: 100vw;
  width: 100vh;
}
</style>
